var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!!_vm.items)?_c('v-timeline',{staticClass:"mt-2 mb-8",attrs:{"align-top":"","dense":""}},_vm._l((_vm.items),function(item,index){return _c('v-timeline-item',{key:index,attrs:{"small":"","right":""}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.formatTime(item.time))),_c('span',{staticClass:"ml-8 mr-2"},[_vm._v("就诊医生："+_vm._s(item.operatorName))])]),_vm._l((item.mrs.tags),function(tag){return _c('v-chip',{key:tag,staticClass:"ml-1",attrs:{"color":"success","small":""}},[_vm._v(_vm._s(tag))])}),(!!item.id)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-6",attrs:{"fab":"","x-small":"","color":"primary","outlined":""},on:{"click":function($event){$event.stopPropagation();return _vm.editItem(item.mrindex)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_vm._v(" 编辑 / 打印 ")]):_vm._e(),(!!item.id)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"fab":"","x-small":"","color":"primary","outlined":"","disabled":_vm.readonly},on:{"click":function($event){$event.stopPropagation();return _vm.remove(item.mrindex)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-trash-can-outline")])],1)]}}],null,true)},[_vm._v(" 删除 ")]):_vm._e(),_c('div',_vm._l((_vm.formatItems(item.mrs)),function(mri){return _c('span',[_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(mri.key)+"：")]),_c('span',[_vm._v(_vm._s(mri.value)+" ")])])}),0),(item.mos && item.mos.length > 0)?_c('div',{staticClass:"mt-3"},[_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v("医嘱项目")]),_c('v-data-table',{staticStyle:{"max-width":"750px","border-bottom":"1px solid #ccc"},attrs:{"items":item.mos,"headers":_vm.moHeaders,"group-by":"mi.category","dense":"","items-per-page":-1,"hide-default-footer":""},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
var isOpen = ref.isOpen;
var toggle = ref.toggle;
var group = ref.group;
return [_c('th',{attrs:{"colspan":"1"}},[_c('v-icon',{on:{"click":toggle}},[_vm._v(_vm._s(isOpen ? 'mdi-minus' : 'mdi-plus')+" ")]),_vm._v(" "+_vm._s(group)+" ")],1)]}}],null,true)})],1):_vm._e()],2)}),1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }