<template>
	<div v-if="authed">
		<splitpanes horizontal class="default-theme" style="height:calc(100vh - 52px)" @resized="setTableHeight" v-resize="setTableHeight">
			<pane min-size="25" size="45" ref="toppane">
				<v-data-table :headers="headers" :items="items" item-key="_id" v-model="selected"
					:options.sync="options" :server-items-length="totalItems" dense
					:footer-props="{ itemsPerPageOptions: [10, 30, 50] }" fixed-header :height="tableheight"
					:loading="loading" loading-text="加载中..." show-select single-select>
				<template v-slot:top>
					<div class="d-flex flex-wrap pb-1" style="gap:16px" ref="tabletopbar">
						<v-text-field placeholder="姓名/证件号/手机号" v-model="psearch" clearable hide-details
							append-icon="search" @click:append="loadData" @keyup.enter.native="loadData" style="flex: 0 1 auto" class="ml-2"/>
						<v-menu v-model="menu1" :close-on-content-click="false" :nudge-right="10"
							transition="scale-transition" offset-y min-width="290px">
							<template v-slot:activator="{ on, attrs }">
								<v-text-field :value="pdates.join(' ~ ')" label="预约门诊日期" v-on="on" v-bind="attrs" readonly hide-details
									append-icon="mdi-close" @click:append="pdates=[]" style="flex: 0 1 auto;width:240px;"></v-text-field>
							</template>
							<v-date-picker v-model="pdates" @change="menu1=false" range locale="zh-cn" scrollable></v-date-picker>
						</v-menu>
						<v-checkbox v-model="showComplete" label="显示已完诊" hide-details class="ml-8" @change="loadData"/>
						<v-checkbox v-model="showEndoscopic" label="显示镜检订单" hide-details class="ml-4" @change="loadData"/>
					</div>
					<v-divider/>
				</template>
				<template v-slot:item.actions="{ item }">
					<v-tooltip bottom>
						<template v-slot:activator="{ on }">
							<v-icon v-on="on" @click="editNote(item)" class="mr-1" :color="item.color">edit_note</v-icon>
						</template>
						患者备注
					</v-tooltip>
					<v-tooltip bottom>
						<template v-slot:activator="{ on }">
							<v-icon v-on="on" @click="editItem(item)">mdi-square-edit-outline</v-icon>
						</template>
						修改基础信息
					</v-tooltip>
				</template>
				<template v-slot:item.age="{ item }">
					{{getAge(item)}}
				</template>
				<template v-slot:item.opts="{ item }">
					{{getOptsStr(item)}}
				</template>
				<template v-slot:item.apntClncDate="{ item }">
					{{formatTime(item.apntClncDate)}}
				</template>
				<template v-slot:footer.prepend>
					<v-tooltip bottom>
						<template v-slot:activator="{ on, attrs }">
							<v-icon v-bind="attrs" v-on="on" large color="primary" class="ml-1" @click.stop="addItem">post_add</v-icon>
						</template>
						新建病案
					</v-tooltip>
				</template>
				</v-data-table>
			</pane>
			<pane min-size="30" size="55" v-if="(selected.length > 0)">
				<div style="max-height:100%;background-color:white;" class="overflow-y-auto pl-2">
					<v-tabs v-model="tab" style="position:sticky;top:0;z-index:1;">
						<v-tab>进度</v-tab>
						<v-tab>病历</v-tab>
					</v-tabs>
					<v-tabs-items v-model="tab">
						<v-tab-item>
							<div class="d-flex pl-4">
								<v-form style="width:240px">
									<v-radio-group label="门诊" v-model="stgClnc" mandatory>
										<v-radio v-for="r,i in stgClncItems" :key="r" :label="r" :value="i===0?null:r"/>
									</v-radio-group>
									<v-btn color="primary" @click.stop="saveStage('门诊', stgClnc)" :loading="loading">保存</v-btn>
								</v-form>
							</div>
						</v-tab-item>
						<v-tab-item>
							<DiagnoseHistoryEditor ref="dhe" :pat="selected[0]"/>
						</v-tab-item>
					</v-tabs-items>
	            </div>
			</pane>
		</splitpanes>
    </div>
    <div v-else>
        无此权限
    </div>
</template>

<script>
    import {formatTime, calculateAge} from '../utils'
    import { Splitpanes, Pane } from 'splitpanes'
    import 'splitpanes/dist/splitpanes.css'
	import DiagnoseHistoryEditor from '../components/DiagnoseHistoryEditor.vue'
	import {stgClncItems, setPatientColor} from '../workset3'
	import NewPatient from '../components/NewPatient3.vue'
	import PatientNote from '../components/PatientNote.vue'

    export default {
        data() {
            return {
                authed: false,
                headers: [
                    {text:'', value:'actions', width:80, sortable: false, class:'pa-0', cellClass:'pa-0'},
                    {text:'病案号', value:'pid', width:100},
                    {text:'姓名', value:'pii.username', width:100},
                    {text:'性别', value:'pii.gender', width:80},
                    {text:'年龄', value:'age', width:80},
                    {text:'电话', value:'pii.phone', width:100},
                    {text:'预约门诊时间', value:'apntClncDate', width:180},
                    {text:'身高cm', value:'pii.bodyheight', width:100},
                    {text:'体重kg', value:'pii.bodyweight', width:100},
                ],
                items: [],
                loading: false,
				selected: [],
				options: {sortBy:['apntClncDate'], sortDesc:[true]},
				totalItems: 0,
				tableheight: undefined,
				canEdit: true,
				psearch: '',
				tab: 0,
				stgClncItems,
				stgClnc: null,
				menu1: false,
				pdates: [],
				showComplete: false,
                showEndoscopic: false,
            }
        },
        created() {
			this.formatTime = formatTime;
        },
        mounted() {
			this.authed = this.$hasPrivilege(['门诊']);
			if (!this.authed) return;
			this.canEdit = this.$hasPrivilege(['门诊']);
        },
        methods: {
			setTableHeight() {
				if (!this.$vuetify.breakpoint.mobile && this.selected.length === 0) {
					this.tableheight = undefined;
					return;
				}
				const tableFooterHeight = 39;
				setTimeout(() => {
    				const ttbh = this.$refs.tabletopbar.getBoundingClientRect().height;
					const paneHeight = this.$refs.toppane.$el.getBoundingClientRect().height;
					this.tableheight = paneHeight - tableFooterHeight - ttbh;
					if (this.selected.length > 0) this.$nextTick(() => this.$vuetify.goTo(".v-data-table__selected", { container: ".v-data-table__wrapper" }) );
				}, 200)
			},
			async loadData() {
				this.selected = [];
				if (this.options.page !== 1) {
					this.options.page = 1;	//自动触发fetchData
					return;
				}
				await this.fetchData();
			},
			async fetchData() {
				const db = this.$tcbapp.database();
				const _ = db.command;
				let filter = [];
				if (this.psearch) {
					filter.push(_.or([{'pii.username':this.psearch}, {'pii.phone':this.psearch}, {'pii.idcard':this.psearch}]));
				}
				if (this.pdates.length === 2) {
					const d1 = new Date(this.pdates[0] + 'T00:00:00+08:00');
					const d2 = new Date(this.pdates[1] + 'T23:59:59+08:00');
					filter.push({apntIntpDate:_.gt(d1).lt(d2)});
				}
				if (!this.showComplete) {
					filter.push({'stages.门诊':_.exists(false)})
				}
                if (!this.showEndoscopic) {
					filter.push({category:'clinic'})
                }
				filter.push({hide:_.neq(true)});
				const f = filter;
				this.loading = true;
				try {
                    const countRes = await db.collection('wp3order').where(_.and(f)).count();
					this.totalItems = countRes.total;
					const { sortBy, sortDesc, page, itemsPerPage } = this.options;
					const res = await db.collection('wp3order').where(_.and(f)).orderBy(sortBy[0]||'_', sortDesc[0] ? 'desc' : 'asc')
						.skip((page-1) * itemsPerPage).limit(itemsPerPage)
						.get();
					await setPatientColor(db, res);
					this.items = res.data;
				} catch(err) {
					console.error(err);
				}
				this.loading = false;
			},
            getAge(item) {
                return calculateAge(item.pii.dob);
            },
			async saveStage(stage, value) {
				const id = this.selected[0]._id;
				this.loading = true;
				try {
					await this.$callCloudFunc({
						funcname:'saveStage',
						data: {
							id,
							stage,
							value,
						}
					});
					await this.fetchData();
					this.$dialog.message.success('保存完成');
				} catch(err) {
					this.$dialog.message.error('保存失败');
					console.error(err);
				}
				this.loading = false;
			},
            async addItem() {
				const onSave = async(pii) => {
					this.loading = true;
					try {
						await this.$callCloudFunc({
							funcname:'createClinicOrder',
							data: {
								pii,
							}
						});
						return true;
					} catch (err) {
						console.error(err);
						return false;
					} finally {
						this.loading = false;
					}
				}
				const pii = {idtype:'身份证'};
				const result = await this.$dialog.showAndWait(NewPatient, {width:600, pii, onSave, editMode:false});
				if (result === true) {
					await this.loadData();
					this.$dialog.message.success('创建完成');
				} else if (result === false) {
					this.$dialog.message.error('创建失败');
				}
			},
			async editItem(item) {
				const onSave = async(pii) => {
					this.loading = true;
					try {
						await this.$callCloudFunc({
							funcname:'updatePii',
							data: {
								id: item._id,
								pii,
							}
						});
						return true;
					} catch (err) {
						console.error(err);
						return false;
					} finally {
						this.loading = false;
					}
				}
				const result = await this.$dialog.showAndWait(NewPatient, {width:600, pii:item.pii, onSave, editMode:true});
				if (result === true) {
					await this.loadData();
					this.$dialog.message.success('修改完成');
				} else if (result === false) {
					this.$dialog.message.error('修改失败');
				}
			},
			async editNote(item) {
				await this.$dialog.showAndWait(PatientNote, {width:600, pid:item.pid});
				await this.fetchData();
			},
		},
		watch: {
			options: {
				handler () {
					this.fetchData()
				},
				deep: true,
			},
			selected(newitem, olditem) {
				if (newitem.length === 0) return;
				this.stgClnc = newitem[0].stages?.['门诊'] || null;
			},
			pdates(ds) {
				if (ds.length === 1) return;
				if (ds.length === 2) {
					if (new Date(ds[0]) > new Date(ds[1])) {
						[ds[0], ds[1]] = [ds[1], ds[0]];
					}
				}
				this.loadData();
			},
		},
        components: { Splitpanes, Pane, DiagnoseHistoryEditor }
    }
</script>
