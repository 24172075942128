<template>
    <v-card :loading="loading" style="height:80vh;position:relative;">
		<template slot="progress">
			<v-progress-linear absolute	color="primary"	height="4" indeterminate/>
		</template>		
        <v-card-title>患者备注</v-card-title>
        <v-card-text style="height:calc(100% - 130px)" class="overflow-y-auto">
			<v-timeline align-top dense class="mr-6">
				<v-timeline-item v-for="item,index in items" :key="index" small :color="getColor(item.color)" fill-dot>
					<span style="font-weight:bold">{{item.operatorName}}</span>
					<div style="display:inline-block;float:right;">
					<span style="font-size:8px">{{formatTime(item.time)}}</span>
					<v-tooltip bottom>
						<template v-slot:activator="{ on }">
							<v-icon small v-show="showDelete(item)" v-on="on" @click.stop="remove(index)" class="ml-2">delete_outline</v-icon>
						</template>
						删除
					</v-tooltip>
					</div>
					<v-card outlined flat>
						<v-card-text>{{item.text}}</v-card-text>
					</v-card>
				</v-timeline-item>
			</v-timeline>
        </v-card-text>
		<div class="mx-6 mt-2 d-flex" style="gap:16px">
			<v-select :items="colors" item-value="text" v-model="color" outlined hide-details dense style="flex:0 0 90px">
				<template #item="{ item }">
					<v-chip :color="item.color" text-color="white">{{item.text}}</v-chip>
				</template>
				<template #selection="{ item }">
					<v-chip :color="item.color" text-color="white">{{item.text}}</v-chip>
				</template>
			</v-select>
			<v-text-field outlined hide-details dense clearable autofocus v-model.trim="text"
				append-outer-icon="add_circle_outline" @click:append-outer.stop="append" @keyup.enter.native="append"/>
		</div>
    </v-card>
</template>

<script>
import {patientColors} from '../workset3'

export default {
	props: ['pid'],
    data() {
        return {
			loading: false,
			text: null,
			items: [],
			colors: patientColors,
			color: '',
        }
    },
	created() {
		this.color = this.colors[0].text;
	},
	mounted() {
		if (this.pid) this.fetchData();
	},
    methods: {
		formatTime(t) {
			return Intl.DateTimeFormat('zh-cn', {dateStyle:'long', timeStyle:'medium'}).format(t);
		},
		getColor(s) {
			return this.colors.find(x => x.text === s)?.color || '';
		},
		showDelete(item) {
			const user = this.$getCurrentUser();
			return item.operator === user.username;
		},
		async fetchData() {
			const db = this.$tcbapp.database();
			this.loading = true;
			try {
				 const res = await db.collection('patient').doc(this.pid).get();
				this.items = res.data[0]?.notes || [];
			} catch(err) {
				console.error(err);
			}
			this.loading = false;
		},
		async append() {
			if (!this.text) {
				this.$dialog.message.error('内容不能为空');
				return;
			}
			this.loading = true;
			try {
				await this.$callCloudFunc({
					funcname:'addPatientNote',
					data: {
						pid: this.pid,
						text: this.text,
						color: this.color,
					}
				});
				await this.fetchData();
			} catch(err) {
				console.error(err);
			}
			this.loading = false;
		},
		async remove(index) {
			const r = await this.$dialog.warning({
				text: '确定要删除此项？',
				title: '删除患者备注'
			});
			if (!r) return;
			this.loading = true;
			try {
				await this.$callCloudFunc({
					funcname:'deletePatientNote',
					data: {
						pid: this.pid,
						index,
					}
				});
				await this.fetchData();
			} catch(err) {
				console.error(err);
			}
			this.loading = false;
		},
    },
}
</script>