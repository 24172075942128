<template>
    <div>
		<v-btn class="ml-8 mt-6 mb-4" rounded width="150" color="primary" @click.stop="addRecord" :disabled="readonly">添加病历记录</v-btn>
		<diagnose-history :items="MedicalHistory" @edit="editRecord" @remove="removeRecord" :readonly="readonly"/>
		<diagnose v-model="diagnose" :patient="patient" :mrindex="mrindex" @change="fetchMr(pat)" :readonly="readonly"/>
    </div>
</template>

<script>
	import DiagnoseHistory from './DiagnoseHistory3.vue'
	import Diagnose from '../components/Diagnose3.vue'

	export default {
		props: ['pat'],
        data() {
            return {
				MedicalHistory: [],
				mrindex: -1,
				diagnose: false,
				readonly: false,
			}
		},
		computed: {
			patient() {
				this.fetchMr(this.pat);
				return this.pat;
			}
		},
		mounted() {
			this.readonly = !this.$hasPrivilege('书写病历');
		},
		methods: {
			addRecord() {
				this.mrindex = -1;
				this.diagnose = true;
			},
			editRecord(idx) {
				this.mrindex = idx;
				this.diagnose = true;
			},
			async removeRecord(index) {
				const res = await this.$dialog.warning({
					text: '确定要删除此项记录？',
					title: '提示'
				});
				if (!res) return;
				const id = this.patient._id;
//				this.loading = true;
				try {
					const res = await this.$callCloudFunc({
						funcname:'removeMedicalRecord',
						data: {
							id,
							index,
						}
					});
					if (!res.result.ret) {
						this.$dialog.error({title:'删除失败', text:res.result.msg});
						return;
					}
					this.fetchMr(this.pat);
				} catch(err) {
					console.error(err);
					this.$dialog.message.error('删除失败');
				} finally {
//					this.loading = false;
				}
			},
			async fetchMr(pat) {
				this.MedicalHistory = [];
				if (!pat) return;
				const db = this.$tcbapp.database();
				const _ = db.command;
				try {
					const res = await db.collection('wp3order')
						.where({
							pid: pat.pid,
							MedicalHistory: _.exists(true),
						})
						.field({MedicalHistory:true})
						.get();
					const r = res.data.find(x => x._id === pat._id);
					if (r) {
						pat.MedicalHistory = r.MedicalHistory;
					}
					this.MedicalHistory = res.data
						.flatMap(x => {
							x.MedicalHistory.forEach((y, index) => {
								if (x._id === pat._id) {
									y.id = x._id;
									y.mrindex = index;
								}
							});
							return x.MedicalHistory;
						})
						.sort((a, b) => a.time - b.time);
				} catch(err) {
					console.error(err);
				}
			},
		},
		components: { DiagnoseHistory, Diagnose },
	}
</script>
