<template>
	<v-card :loading="loading" class="pb-3" style="position:relative">
		<template slot="progress">
			<v-progress-linear absolute	color="primary"	height="4" indeterminate/>
		</template>		
		<v-card-title>基础信息</v-card-title>
		<v-card-text>
			<v-form ref="formNew" class="px-4">
				<div class="d-flex" style="gap:16px">
					<v-select label="证件类型" :items="['身份证','护照','军官证','其他']" v-model="mypii.idtype" @change="parseIdcard" style="flex:0 0 48%" :disabled="editMode"/>
					<v-text-field label="证件号码" v-model.trim="mypii.idcard" :rules="cnltRules" :error-messages="iderr ? '身份证号无效' : ''"
						@input="parseIdcard" append-icon="search" @click:append="query" @keyup.enter.native="query" style="flex:0 0 48%" :disabled="editMode"/>
				</div>
				<div class="d-flex" style="gap:16px">
					<v-text-field label="客户姓名" v-model.trim="mypii.username" :rules="cnltRules" style="flex:0 0 48%"/>
					<v-text-field label="手机号" v-model.trim="mypii.phone" :rules="phoneRules" style="flex:0 0 48%"/>
				</div>
				<div class="d-flex" style="gap:16px">
					<v-select label="性别" :items="['男','女']" v-model="mypii.gender" :rules="cnltRules" :readonly="mypii.idtype==='身份证'" style="flex:0 0 22%"/>
					<v-menu v-model="menuDob" :close-on-content-click="false" :nudge-right="10"
						transition="scale-transition" offset-y min-width="290px">
						<template v-slot:activator="{ on, attrs }">
							<v-text-field v-model="mypii.dob" label="出生日期" readonly v-bind="attrs"
								v-on="on" :rules="cnltRules" style="flex:0 0 23%"></v-text-field>
						</template>
						<v-date-picker v-model="mypii.dob" @change="menuDob=false" no-title scrollable :readonly="mypii.idtype==='身份证'"></v-date-picker>
					</v-menu>
					<v-text-field label="身高(厘米)" type="number" v-model.number="mypii.bodyheight" :min="1" :rules="numberRules" style="flex:0 0 23%"/>
					<v-text-field label="体重(公斤)" type="number" v-model.number="mypii.bodyweight" :min="1" :rules="numberRules" style="flex:0 0 22%"/>
				</div>
			</v-form>
		</v-card-text>
		<v-card-actions>
			<v-spacer/>
			<v-btn large color="primary" :loading="loading" :disabled="loading" @click.stop="save">确定</v-btn>
			<v-btn large outlined :disabled="loading" @click.stop="cancel">返回</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
	import {checkIdCard} from '../utils'

	export default {
		props: ['pii', 'onSave', 'editMode'],
		data() {
			return {
				loading: false,
				mypii: this.pii || {},
				cnltRules: [v => !!v || '此项为必填'],
				phoneRules: [v => (/^[1][3,4,5,6,7,8,9][0-9]{9}$/).test(v) || '手机号格式不正确'],
				numberRules: [v => (/^-?[0-9]+(.[0-9]*)?$/g).test(v) || '只允许数字'],
				menuDob: false,
				iderr: false,
			}
		},
		methods: {
			cancel() {
				this.$emit('submit', null);
			},
			async save() {
				if (!this.$refs.formNew.validate()) return;
				if (this.mypii.idtype==='身份证' && !checkIdCard(this.mypii.idcard)) {
					this.iderr = true;
					return;
				}
				if (this.onSave) {
					const res = await this.onSave(this.mypii);
					this.$emit('submit', res);
				}
			},
			parseIdcard() {
				this.iderr = false;
				if (this.mypii.idtype==='身份证' && checkIdCard(this.mypii.idcard)) {
					const idcard = this.mypii.idcard;
					this.mypii.dob = idcard.substr(6,8).replace(/(.{4})(.{2})/,"$1-$2-");
					this.mypii.gender = idcard[16] % 2 === 1 ? '男' : '女';
				}
			},
			async query() {
				if (!this.mypii.idcard) return;
				const db = this.$tcbapp.database();
				const _ = db.command;
				this.loading = true;
				try {
					const res = await db.collection('wp3order').where({'pii.idtype':this.mypii.idtype,'pii.idcard':this.mypii.idcard}).orderBy('pid', 'desc').limit(1).get();
					if (res.data.length > 0) {
						this.mypii = res.data[0].pii;
					} else {
						this.$dialog.message.info('没有查询到历史信息');
					}
				} catch(err) {
					console.error(err);
				}
				this.loading = false;
			},
		}
	}
</script>
