<template>
	<v-timeline align-top dense v-if="!!items" class="mt-2 mb-8">
		<v-timeline-item v-for="(item,index) in items" :key="index" small right>
			<span class="font-weight-bold">{{formatTime(item.time)}}<span class="ml-8 mr-2">就诊医生：{{item.operatorName}}</span></span>
			<v-chip v-for="tag in item.mrs.tags" :key="tag" color="success" small class="ml-1">{{tag}}</v-chip>
			<v-tooltip bottom v-if="!!item.id">
				<template v-slot:activator="{ on, attrs }">
		            <v-btn v-bind="attrs" v-on="on" fab x-small class="ml-6" color="primary" outlined @click.stop="editItem(item.mrindex)"><v-icon>mdi-pencil</v-icon></v-btn>
				</template>
				编辑 / 打印
			</v-tooltip>
			<v-tooltip bottom v-if="!!item.id">
				<template v-slot:activator="{ on, attrs }">
		            <v-btn v-bind="attrs" v-on="on" fab x-small class="ml-2" color="primary" outlined @click.stop="remove(item.mrindex)" :disabled="readonly">
						<v-icon>mdi-trash-can-outline</v-icon>
					</v-btn>
				</template>
				删除
			</v-tooltip>
			<div>
				<span v-for="mri in formatItems(item.mrs)">
					<span style="font-weight:bold;">{{mri.key}}：</span>
					<span>{{mri.value}}　</span>
				</span>
			</div>
			<div v-if="item.mos && item.mos.length > 0" class="mt-3">
				<span style="font-weight:bold;">医嘱项目</span>
				<v-data-table :items="item.mos" :headers="moHeaders" group-by="mi.category" dense style="max-width:750px;border-bottom:1px solid #ccc;" :items-per-page="-1" hide-default-footer>
					<template v-slot:group.header="{isOpen, toggle, group}">
						<th colspan="1">
							<v-icon @click="toggle"
								>{{ isOpen ? 'mdi-minus' : 'mdi-plus' }}
							</v-icon>
							{{ group }}
						</th>
					</template>
				</v-data-table>
			</div>
		</v-timeline-item>
	</v-timeline>
</template>

<script>
    import {formatTime} from '../utils'
	import {mritems} from '../workset3'

	export default {
		props: {
			items: {
				type: Array,
				default: () => []
			},
			readonly: {
				type: Boolean,
				default: true,
			},
		},
        data() {
            return {
				mritems,
				moHeaders: [
					{text:'分类', value:'mi.category', width:100},
					{text:'项目', value:'mi.name', width:260},
					{text:'剂量', value:'mi.unitdose', width:100},
					{text:'用法', value:'mo.routes', width:120},
					{text:'频次', value:'mo.times', width:120},
					{text:'天数', value:'mo.days', width:80},
				],
			}
		},
		created() {
			this.formatTime = formatTime;
		},
		methods: {
			formatItems(items) {
				if (!items) return [];
				let r = [];
				this.mritems.forEach(x => {
					if (items[x]) r.push({key:x, value:items[x]});
				});
				return r;
			},
			editItem(index) {
				this.$emit('edit', index);
			},
			remove(index) {
				this.$emit('remove', index);
			},
		},
	}
</script>